body, html, #root, .LoadingContianer {
    height: 100%;
    width: 100%;
    background-color: #3c454d;
}

body{
    margin: 0;
}

.LoadingContianer{
    background-color: #3c454d;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.loadingImage{
    height: 30%;
  /* width: 10%; */
    position: absolute;
    top: 50%;
    left: 50%;
    user-select: none;
    transform: translate(-50%, -50%);
}

.dataContainer{
    /* height: 40%; */
    /* width: 10%; */
    position: absolute;
    top: 75%;
    left: 50%;
    user-select: none;
    transform: translate(-50%, -50%);
    text-align: center;

  }

  span.inintlaizing {
    display: inline-block;
    text-align: center;
    /* width: 68px; */
    height: 100%;
}

p.appName,
.appInitializing {
    margin: 0;
    color: white;
    
}


.inintlaizing p {
    display:inline;
    float: left;
    /* margin-left:10px; */
}
.inintlaizing img {
    float:left;
    margin-top: 8px;
}

p.appName{
    font-size: 35px;
    text-align: center;
}

.appInitializing {
    font-size: 17px;
    text-align: center;
}

