.mainDivForDemo{
    background-color: #E6E7E6;
    height: 100% !important;
}

.DarkTheme.mainDivForDemo{
    background-color: #30373D;
    height: 100% !important;
}

.LiUsrRouteMainDiv{
    width:100% !important;
    /* border: 1px solid red; */
    height: 300px !important;
}
.LiUsrRouteInnerDiv{
    width: 33% !important;
    display: inline-block;
    /* border: 1px solid blue; */
    padding-top: 75px;
    text-align: -webkit-center;
    text-align: -moz-center
}

.DarkTheme.LiUsrMgmtBottom{
    border: 1px solid #282b30;
    margin-left: -10px;
}

.LightTheme.LiUsrMgmtBottom{
    border: 1px solid #cacbcd;
}


.avatarSecRadius{
    border: none !important;
}
.liDefaultWidth{
    width:100% !important
}
.Theme1.LiuserEditCompRight{
    width: 200px !important;
    font-size: 15px !important;
    height: 32px !important;
    bottom: 2
}

.Theme1.LiuserEditCompRight div div div{
    top: 5px !important;
    line-height: 30px !important;
}

.Theme1.LiuserEditCompRight div hr:nth-child(1) {
    bottom: 4px !important;
    border-bottom:1px solid grey !important;
}

.Theme1.LiuserEditCompRight div hr:nth-child(2) {
    bottom: 4px !important;
    border-bottom:1px solid #881B4C !important;
}

.Theme1.dialogButton{
    /* background: linear-gradient(to left,rgb(248, 141, 189),#881B4C) !important; */
    background: #3c454b !important;
    text-decoration: none;
    color:white !important;
    /* font-family: sans-serif; */
}



/* Theme2 */

.Theme2.LiuserEditCompRight{
    width: 200px !important;
    font-size: 15px !important;
    height: 32px !important;
    bottom: 2
}

.Theme2.LiuserEditCompRight div hr:nth-child(1) {
    bottom: 4px !important;
    border-bottom:1px solid grey !important;
}

.Theme2.LiuserEditCompRight div hr:nth-child(2) {
    bottom: 4px !important;
    border-bottom:1px solid blue !important;
}

.Theme2.dialogButton{
    /* background: linear-gradient(to left,rgb(248, 141, 189),#881B4C) !important; */
    background: #3c454b !important;
    text-decoration: none;
    color:white !important;
    /* font-family: sans-serif; */   
}



.LiuserEditMainDiv{
    margin-bottom: 20px !important;
    padding: 20px;
    float: left;
}
.LiuserEditAvatarDiv{
    padding: 5px;
    float: right;
    cursor: pointer;
}
.LiuserEditSpanLeft{
    width:200px;
    float:left;
}

.LiuserEditCompToggle {
    display: block !important;
    float: left;
    width:100px !important;
    padding-top: 10px;
}

/* .LiuserEditCompToggle input {
    width:50px !important;
} */

.LiuserEditCompToggle div {
    display: block !important;
}

.LiuserEditCompButton button{
    background-color: slategray !important;
}

.imageFloatRight{
    float: right;
    cursor: pointer;
    padding-bottom: 5px;
    padding-top: 15px;
}

#userAva{
    width: 150px !important;
    height: 150px !important;
    border-radius: 5%;
    cursor: pointer;
    border: 1px solid #cacbcd;
}

@media only screen and (max-width: 661px) {
    .LiUserEditPanel-header{
    font-weight: bold;
    color: rgba(39, 38, 38, 0.753);
    width:100% !important;
    }

    .LiuserEditCompRight{
        width:100% !important;
    }
    .LiuserEditbuttonDiv{
        width:100% !important;
        margin-bottom: 10px
    }

    .LiuserEditCompToggle {
        width:100% !important;
        padding-bottom: 10px;
    }

    .LiuserEditAvatarDiv{
        float: left;
    }

    .LiuserEditCompButton{
        width: 100%;
    }

    #userAva{
        width: 100px !important;
        height: 100px !important;
    }
}

@media only screen and (max-width: 708px) {
    .LiuserEditAvatarDiv{
        float: left;
    }
    #userAva{
        width: 100px !important;
        height: 100px !important;
    }
}

.messageRed{
    color: red;
}

.ui-dialog {
    font-size: 15px !important;
}

.mu-datepicker-width-style div{
    width:150px !important;
}


.sliderPropStyle{
  width: 200px;
}

.sliderPropStyle div{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
  
.Available{
    border: 1px solid #eaeaea !important;
    width: 300px !important;
    float: left;
    cursor: pointer;
    border-radius: 2px;
}

.droppable{
    border: 1px solid #eaeaea !important;
    width: 300px !important;
    float: left;
    cursor: pointer;
}
.spacerDiv{
    float: left;
    width: 50px !important;
}

@media only screen and (max-width: 411px) {
    .divSixty{
        width:100% !important;
    }

    .divFifty{
        width:100% !important;
    }

    .divFourty{
        width:100% !important;
    }
    
    .divThirty{
        width: 100% !important;
    }
    
    .divTwentyFive{
        width:100% !important;
    }

    .divTwenty{
        width:100% !important;
    } 

    .divFifteen{
        width:100% !important;
    }

    .divTen{
        width:100% !important;
    }

    .divFive{
        width:100% !important;
    }
  
}

@media only screen and (min-width: 412px) and (max-width: 768px) {
    .divSixty{
        width:100% !important;
    }

    .divFifty{
        width:100% !important;
    }

    .divFourty{
        width:100% !important;
    }
    
    .divThirty{
        width: 100% !important;
    }
    
    .divTwentyFive{
        width:100% !important;
    }

    .divTwenty{
        width:100% !important;
    } 

    .divFifteen{
        width:100% !important;
    }

    .divTen{
        width:100% !important;
    }

    .divFive{
        width:100% !important;
    }
}

@media only screen and (min-width: 769px) and (max-width: 972px) {
    .divSixty{
        width:100% !important;
    }

    .divFifty{
        width:100% !important;
    }

    .divFourty{
        width:100% !important;
    }
    
    .divThirty{
        width: 100% !important;
    }
    
    .divTwentyFive{
        width:100% !important;
    }

    .divTwenty{
        width:100% !important;
    } 

    .divFifteen{
        width:100% !important;
    }

    .divTen{
        width:100% !important;
    }

    .divFive{
        width:100% !important;
    }

}

@media only screen and (min-width: 973px) and (max-width: 1130px) {
    .divSixty{
        width:100% !important;
    }

    .divFifty{
        width:100% !important;
    }

    .divFourty{
        width:100% !important;
    }
    
    .divThirty{
        width: 100% !important;
    }
    
    .divTwentyFive{
        width:100% !important;
    }

    .divTwenty{
        width:100% !important;
    } 

    .divFifteen{
        width:100% !important;
    }

    .divTen{
        width:100% !important;
    }

    .divFive{
        width:100% !important;
    }
    
}

@media only screen and (min-width: 1130px) {
    .divHundred{
        width:100% !important;
        float: left;
        /* border: 1px solid grey; */
    }
    .divNinty{
        width:90% !important;
        float: left;
        /* border: 1px solid blue; */
    }
    .divEighty{
        width:80% !important;
        float: left;
        /* border: 1px solid blue; */
    }

    .divSixty{
        width:60% !important;
        float: left;
        /* border: 1px solid blue; */
    }

    .divFifty{
        width:50% !important;
        float: left;
        /* border: 1px solid yellowgreen; */
    }
    .divFourty{
        width:40% !important;
        float: left;
        /* border: 1px solid red; */
    }

    .divFourtyRight{
        width:40% !important;
        float: right;
        /* border: 1px solid red; */
    }
    
    .divThirty{
        width: 30% !important;
        float: left;
        /* border: 1px solid green; */
    }
    
    .divTwentyFive{
        width:25% !important;
        float: left;
        /* border: 1px solid blue; */
    }

    .divTwentyFive div div div{
        top: 5px !important;
        line-height: 15px !important;
        /* border: 1px solid blue; */
    }

    .divTwenty{
        width:20% !important;
        float: left;
        /* border: 1px solid blue; */
    }

    .divTwenty div div div{
        top: 5px !important;
        line-height: 15px !important;
    }

    .divFifteen{
        width:15% !important;
        float: left;
        /* border: 1px solid olive; */
    } 

    .divFifteenRight{
        width:15% !important;
        float: right;
        /* border: 1px solid olive; */
    } 

    .divTen{
        width:10% !important;
        float: left;
    } 

    .divFive{
        width:5% !important;
        float: left;
        /* border: 1px solid olive; */
    } 
}
.outerGrid{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* grid-template-columns: 600px 800px; */
    /* width: 100%; */
    margin-left: 50px;
    /* border: 1px solid blue; */
}
.outerGridItemLeft {
    padding: 5px;
    width : 40%;
    vertical-align: middle;
    line-height: 1.5;
    padding-bottom: 30px;
    /* border: 1px solid orange; */
}
.outerGridItemRight{
    padding: 5px;
    width : 60%;
    vertical-align: middle;
    line-height: 1.5;
    padding-bottom: 30px;
}
.addSiteMainGridContainerSecond {
     /* display: grid;  */
    grid-template-columns:800px;
    /* width:890px; */
    /* border: 1px solid green; */
    /* background-color: blueviolet; */
}
.addSiteMainGridContainer {
    display: grid;
    grid-template-columns: 200px 390px;
    width: 590px;
    /* border: 1px solid green; */
}

.RoleEditRoleAdGroup{
    /* grid-template-columns:800px; */
    /* width: 590px;; */
}
@media only screen and (max-width: 3350px) {
.twoColumnMainGridContainerItem #roleDescField{
    width : 50% !important;
} 
.twoColumnMainGridContainerItem #roleShdescField{
    width : 50% !important;
} 
}

.twoColumnMainGridContainer {
    display: grid;
    grid-template-columns: 200px 600px;
    width: 800px;
    margin-left: 175px;

    /* border: 1px solid red; */
}
.twoColumnMainGridContainerItemSecond {
    padding: 5px;
    vertical-align: middle;
    line-height: 1.5;
    padding-bottom: 30px;
    /* background-color: bisque; */
    /* border: 1px solid violet; */
}
.twoColumnMainGridContainerItem {
    padding: 5px;
    vertical-align: middle;
    line-height: 1.5;
    padding-bottom: 30px;
    /* border: 1px solid violet; */
}

.RoleAddSiteWidth{
    width: 310px !important;
}


.userMgmtGridContainerFull {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
}

.userMgmtGridContainerFullItem {
    background: none !important;
    padding: 3px;
    text-align: right;
    /* border: 1px solid blue; */
}

.userMgmtGridContainerFullItemLeft{
    float: left;
}

.userMgmtGridContainer {
    display: grid;
    grid-template-columns: 25% 75%;
    width: 100%;
}

.userMgmtGridContainerItem {
    background: none !important;
    padding: 3px;
    /* border: 1px solid blue; */
}

.userMgmtGridContainerInner {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 100%;
    /* border: 1px solid blue; */
}

.userMgmtGridContainerInnerItem {
    /* border: 1px solid blue; */
    background: none !important;
    padding: 5px;
    display: inline;
    vertical-align: center;
}


.userMgmtGridContainerInnerSub {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    width: 100%;
}

.userMgmtspanFloatLeft{
    float: left;
    width: 100%;
    padding: 2px 5px;
}

.user_mandatoryFieldNote {
    color: red;
    font-weight: bold;
}

.LightTheme.Rowtext {
    width: 90% !important;
    
}

.DarkTheme.Rowtext {
    width: 90% !important;
    
}

.userMgmtGridContainerFull .ui-dialog{
    text-align: start !important;
}

.AddUserADTableContainer{
    width:  98%;
    margin-top: 10px;
    /* margin-bottom: 150px; */
}

.Usermanagement_AzureAD_SearchUser{
    display: flex;
    align-items: center;
}

.Usermanagement_AzureAD_SearchUser .LiLineEdit_main{
    width: 87%;
}

.AddUserADTableContainer .LiButton_ParentNode{
    float: right;
}

.RoleGroupADList{
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.RoleGroupADListEdit{
width: 100%;
}

.RoleGroupADListEditBlock{
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 1.5%;
    width: 95%;
    background-color: var(--lighttheme-black-variant5) !important;
    border-style: solid;
    border-color: #282b30;
    border-width: 1px; 
}
.LightTheme.RoleGroupADListEditBlock{
    background-color: var(--lighttheme-black-variant5) !important;
    border-color: #282b30;
}

.DarkTheme.RoleGroupADListEditBlock{
    background-color: var(--darktheme-black-variant3) !important;
    border-color: var(--darktheme-white-variant1);
}

.usrMasterData_Main {
    /* width: 100%; */
    padding: 5px;

    /* border: 1px solid orangered; */
}
.usrMasterData_GridItem {
    /* background: none !important; */

    /* min-width: 350px; */
    
    display: inline;
    vertical-align: middle;
    margin: 3px 2px;

    /* border: 1px solid yellow; */
}
.usrMasterData_GridContainer {
    /* width: 100%; */

    display: grid;
    grid-template-columns: 20% 28% 20% 28%;
    margin: 5px;
    
    /* border: 1px solid blue; */
}
.usrMasterData_GridItem {
    /* background: none !important; */

    /* min-width: 350px; */
    
    display: inline;
    vertical-align: middle;
    margin: 3px 2px;

    /* border: 1px solid yellow; */
}
.usrMasterData_normal {
    /* margin-top: 20px; */
    font-weight: bold;
    /* font-size: 12px; */
}

.usrMasterData_bold {
    /* font-size: 15px; */
}