/* neusa */
@font-face {
    font-family: 'neusalight';
    src: url('./Neusa/neusa_light-webfont.eot');
    src: url('./Neusa/neusa_light-webfont.eot?#iefix') format('embedded-opentype'), url('./Neusa/neusa_light-webfont.woff2') format('woff2'), url('./Neusa/neusa_light-webfont.woff') format('woff'), url('./Neusa/neusa_light-webfont.ttf') format('truetype'), url('./Neusa/neusa_light-webfont.svg#neusaregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'neusaregular';
    src: url('./Neusa/neusa_regular-webfont.eot');
    src: url('./Neusa/neusa_regular-webfont.eot?#iefix') format('embedded-opentype'), url('./Neusa/neusa_regular-webfont.woff2') format('woff2'), url('./Neusa/neusa_regular-webfont.woff') format('woff'), url('./Neusa/neusa_regular-webfont.ttf') format('truetype'), url('./Neusa/neusa_regular-webfont.svg#neusaregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'neusamedium';
    src: url('./Neusa/neusa_medium-webfont.eot');
    src: url('./Neusa/neusa_medium-webfont.eot?#iefix') format('embedded-opentype'), url('./Neusa/neusa_medium-webfont.woff2') format('woff2'), url('./Neusa/neusa_medium-webfont.woff') format('woff'), url('./Neusa/neusa_medium-webfont.ttf') format('truetype'), url('./Neusa/neusa_medium-webfont.svg#neusamedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'neusademibold';
    src: url('./Neusa/neusa_demibold-webfont.eot');
    src: url('./Neusa/neusa_demibold-webfont.eot?#iefix') format('embedded-opentype'), url('./Neusa/neusa_demibold-webfont.woff2') format('woff2'), url('./Neusa/neusa_demibold-webfont.woff') format('woff'), url('./Neusa/neusa_demibold-webfont.ttf') format('truetype'), url('./Neusa/neusa_demibold-webfont.svg#neusademibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'neusasemibold';
    src: url('./Neusa/neusa_semibold-webfont.eot');
    src: url('./Neusa/neusa_semibold-webfont.eot?#iefix') format('embedded-opentype'), url('./Neusa/neusa_semibold-webfont.woff2') format('woff2'), url('./Neusa/neusa_semibold-webfont.woff') format('woff'), url('./Neusa/neusa_semibold-webfont.ttf') format('truetype'), url('./Neusa/neusa_semibold-webfont.svg#neusasemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Roboto */
@font-face {
    font-family: 'Roboto-Medium';
    src: url('./Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Regular';
    src: url('./Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Light';
    src: url('./Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html, body {
    height: 100%;
    width: 100%;
    position: fixed;
    margin: 0;
}

body {
    /* font-family: 'Roboto-Light', Arial, sans-serif !important; */
    font-family: 'Roboto-Regular', Arial, sans-serif !important;
    
    font-size: 14px !important;
    color: black !important;
}
  
h1,h2,h3,h4,h5,h6, .LiLayout_appHeading {
    /* font-family: 'neusaregular', Arial, sans-serif !important; */
    font-family: 'Roboto-Light', Arial, sans-serif !important;

}

.LiGeneral_actionButton {
    background-color : #3c454b !important;
    color: white !important;
}

.LiGeneral_raisedButton {        
}

.LiGeneral_raisedButton button {
    background-color : #3c454b !important;
}

.LiGeneral_raisedButton button div div span{
    color: white !important;
}
